<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="12" md="6">
        <NewSearchBar
          v-if="!isUserSyncLogsPage && !isSyncDetailsPage"
          v-model.trim="selectedFilters.search"
          @search="applyFilters()"
          searchLabel="Search by user's name..."
        />
      </v-col>
      <v-spacer />
      <v-col class="shrink">
        <StandardFilter
          v-if="!isSyncDetailsPage"
          :default="defaultFilters()"
          :fields="filterFields()"
          :filters="filters"
          @apply="applyFilters($event)"
        />
      </v-col>
      <v-col class="shrink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="exportRecords"
              v-on="on"
              color="msaBlue"
              class="white--text"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template>
          Export
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text">
            Synchronization Records
            <v-badge
              :content="recordsCount"
              :value="recordsCount"
              bordered
              class="ml-2"
              color="msaBlue"
              inline
            >
            </v-badge>
          </v-card-title>
          <v-data-table
            :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
            :headers="tableHeaders()"
            :items="records"
            :options.sync="options"
            :server-items-length="recordsCount"
            must-sort
          >
            <template v-if="isInternalAdmin" v-slot:[`item.userId`]="{ item }">
              <span
                @click="goToUserDetail(item.userId)"
                class="msaBlue--text"
                style="cursor: pointer"
              >
                {{ item.userId }}
              </span>
            </template>
            <template
              v-if="isInternalAdmin && !isSyncDetailsPage"
              v-slot:[`item.syncGuid`]="{ item }"
            >
              <span
                @click="goToSyncDetail(item.syncGuid)"
                class="msaBlue--text"
                style="cursor: pointer"
              >
                {{ item.syncGuid }}
              </span>
            </template>
            <template v-slot:[`item.lastSync`]="{ item }">
              {{
                item.lastSync
                  | filterAsLocalDateTime((mask = 'yyyy-LL-dd h:mm a'))
              }}
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              {{
                item.createdAt
                  | filterAsLocalDateTime((mask = 'yyyy-LL-dd h:mm a'))
              }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                :color="item.status == 'Synced' ? 'green' : 'red'"
                label
                small
                text-color="white"
              >
                {{ item.status }}
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn color="msaBlue" disabled small> sync </v-btn>
                  </span>
                </template>
                Feature Not Implemented
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NewSearchBar from '@/components/NewSearchBar.vue';
import StandardFilter from '@/components/Filters/StandardFilter.vue';
export default {
  name: 'SyncLogsPage',
  components: {
    NewSearchBar,
    StandardFilter,
  },
  filters: {
    truncate(text, length = 200) {
      if (text.length <= length) {
        return text;
      }
      return text.substring(0, length) + '...';
    },
  },
  data() {
    return {
      filters: JSON.parse(JSON.stringify(this.defaultFilters())),
      options: {
        sortBy: [],
        sortDesc: [true],
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      },
      records: [],
      recordsCount: 0,
      /**
       * selectedFilters is required on this page, as the searchbar is
       * modeling data in the filter. */
      selectedFilters: {},
    };
  },
  watch: {
    options: {
      handler(newVal, oldVal) {
        const changed = Object.keys(oldVal).some(
          (key) => oldVal[key].toString() != newVal[key].toString(),
        );

        if (changed) {
          this.updateUrl();
        }
      },
      deep: true,
    },
  },
  computed: {
    isInternalAdmin() {
      return (
        this.$store.state.user.type == this.$constants.USER_TYPES.INTERNAL_ADMIN
      );
    },
    /**
     * This property is to determine whether the page is serving the
     * sync-specific details page or not
     */
    isSyncDetailsPage() {
      return this.$route.name == 'SyncDetails';
    },
    /**
     * This property is to determine whether the page is serving the
     * user-specific sync details, or the broad company-related sync logs
     */
    isUserSyncLogsPage() {
      return this.$route.name == 'UserSyncLogs';
    },
  },
  async mounted() {
    this.options.sortBy[0] = this.defaultSortBy();
    this.getRouteQueries();
    await this.getRecords();
    if (this.isUserSyncLogsPage) {
      this.getUserDetails();
    }
  },
  companyDefaultFilters: {
    status: ['Incomplete', 'Synced'],
    search: '',
  },
  companyFilterFields: [
    {
      type: 'text-field',
      title: "User's Name",
      key: 'search',
      placeholder: "Search by a user's name...",
    },
    {
      type: 'multi-select',
      title: 'Status',
      key: 'status',
      options: [
        { title: 'Synced', value: 'Synced' },
        { title: 'Incomplete', value: 'Incomplete' },
      ],
    },
  ],
  companyHeaders: [
    {
      text: 'User ID',
      value: 'userId',
      align: 'start',
      class: 'headerTextGrey--text lightGrey',
      width: '90px',
    },
    {
      text: 'First Name',
      value: 'firstName',
      align: 'start',
      class: 'headerTextGrey--text lightGrey',
      width: '140px',
    },
    {
      text: 'Last Name',
      value: 'lastName',
      align: 'start',
      class: 'headerTextGrey--text lightGrey',
    },
    {
      text: 'Release',
      value: 'clientOs',
      align: 'center',
      class: 'headerTextGrey--text lightGrey',
      width: '140px',
      sortable: false,
    },
    {
      text: 'Last Sync',
      value: 'lastSync',
      align: 'center',
      class: 'headerTextGrey--text lightGrey',
      width: '180px',
    },
    {
      text: 'Status',
      value: 'status',
      align: 'center',
      class: 'headerTextGrey--text lightGrey',
      width: '100px',
    },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      class: 'headerTextGrey--text lightGrey',
      width: '120px',
      sortable: false,
    },
  ],
  detailDefaultFilters: {
    dateFrom: '',
    dateTo: '',
    status: ['Incomplete', 'Synced'],
  },
  detailFilterFields: [
    {
      type: 'date-range',
      title: 'Synced',
      key: { from: 'dateFrom', to: 'dateTo' },
    },
    {
      type: 'multi-select',
      title: 'Status',
      key: 'status',
      options: [
        { title: 'Synced', value: 'Synced' },
        { title: 'Incomplete', value: 'Incomplete' },
      ],
    },
  ],
  userDetailHeaders: [
    {
      text: 'Device ID',
      value: 'deviceId',
      align: 'start',
      class: 'headerTextGrey--text lightGrey',
      width: '320px',
      sortable: false,
    },
    {
      text: 'Device Info',
      value: 'deviceInfo',
      align: 'start',
      class: 'headerTextGrey--text lightGrey',
      sortable: false,
    },
    {
      text: 'Release',
      value: 'clientOs',
      align: 'start',
      class: 'headerTextGrey--text lightGrey',
      width: '100px',
      sortable: false,
    },
    {
      text: 'Sync ID',
      value: 'syncGuid',
      align: 'start',
      class: 'headerTextGrey--text lightGrey',
      width: '320px',
      sortable: false,
    },
    {
      text: 'Synced',
      value: 'createdAt',
      align: 'start',
      class: 'headerTextGrey--text lightGrey',
      width: '175px',
    },
    {
      text: 'Status',
      value: 'status',
      align: 'center',
      class: 'headerTextGrey--text lightGrey',
      width: '90px',
    },
  ],
  syncDetailHeaders: [
    {
      text: 'Device ID',
      value: 'deviceId',
      align: 'start',
      class: 'headerTextGrey--text lightGrey',
      width: '320px',
      sortable: false,
    },
    {
      text: 'Release',
      value: 'clientOs',
      align: 'start',
      class: 'headerTextGrey--text lightGrey',
      width: '100px',
      sortable: false,
    },
    {
      text: 'Sync ID',
      value: 'syncGuid',
      align: 'start',
      class: 'headerTextGrey--text lightGrey',
      width: '320px',
      sortable: false,
    },
    {
      text: 'Controller',
      value: 'controller',
      align: 'start',
      class: 'headerTextGrey--text lightGrey',
      width: '140px',
      sortable: false,
    },
    {
      text: 'Action',
      value: 'action',
      align: 'start',
      class: 'headerTextGrey--text lightGrey',
      width: '140px',
      sortable: false,
    },
    {
      text: 'Synced',
      value: 'createdAt',
      align: 'start',
      class: 'headerTextGrey--text lightGrey',
      width: '175px',
    },
    {
      text: 'Status',
      value: 'status',
      align: 'center',
      class: 'headerTextGrey--text lightGrey',
      width: '90px',
    },
  ],
  methods: {
    updateUrl() {
      let params = this.$helpers.serializeFilter(
        this.filters,
        this.defaultFilters(),
      );
      const options = this.$helpers.serializeOptions(
        this.options,
        this.defaultSortBy(),
        true,
      );
      params = { ...params, ...options };

      this.$router
        .replace({ name: this.$route.name, query: params })
        .catch(() => {});
    },
    getUserDetails() {
      // Clear the user's name in the breadcrumbs, and then update them
      this.$store.commit('updateSelectedUser', {
        firstName: '',
        lastName: '',
      });
      const params = {
        employeeId: this.$route.params.id,
      };
      const url = 'get-user-details?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.userInformation = { ...response.data };
          this.$store.commit('updateSelectedUser', {
            id: this.userInformation.id,
            username: this.userInformation.username,
            firstName: this.userInformation.firstName,
            lastName: this.userInformation.lastName,
            status: this.userInformation.status,
          });
        })
        .catch((error) => error);
    },
    getRouteQueries() {
      const query = this.$route.query;

      this.filters = this.$helpers.deserializeFilters(
        query,
        this.defaultFilters(),
      );
      this.selectedFilters = JSON.parse(JSON.stringify(this.filters));
      this.options = this.$helpers.deserializeOptions(
        query,
        this.defaultSortBy(),
        true,
      );
    },
    defaultSortBy() {
      return this.isUserSyncLogsPage || this.isSyncDetailsPage
        ? 'createdAt'
        : 'lastSync';
    },
    defaultFilters() {
      return this.isUserSyncLogsPage || this.isSyncDetailsPage
        ? this.$options.detailDefaultFilters
        : this.$options.companyDefaultFilters;
    },
    filterFields() {
      return this.isUserSyncLogsPage || this.isSyncDetailsPage
        ? this.$options.detailFilterFields
        : this.$options.companyFilterFields;
    },
    tableHeaders() {
      if (this.isUserSyncLogsPage) {
        return this.$options.userDetailHeaders;
      } else if (this.isSyncDetailsPage) {
        return this.$options.syncDetailHeaders;
      }
      return this.$options.companyHeaders;
    },
    applyFilters(filters = this.selectedFilters) {
      this.filters = JSON.parse(JSON.stringify(filters));
      this.options.page = 1;
      this.updateUrl();
    },
    exportRecords() {
      const params = {
        options: this.options,
        filters: this.filters,
        loaderText: 'Loading...',
      };
      let url = 'export-sync-records-by-company?format=json';
      if (this.isUserSyncLogsPage) {
        params.userId = this.$route.params.id;
        url = 'export-sync-records-by-user?format=json';
      } else if (this.isSyncDetailsPage) {
        params.guid = this.$route.params.guid;
        url = 'export-sync-records-by-guid?format=json';
      } else if (this.$route.params.companyId) {
        params.companyId = this.$route.params.companyId;
      }
      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };
      this.$axios
        .post(url, params, options)
        .then((response) => {
          this.$helpers.downloadFile(response.data, 'Sync Records.xlsx');
        })
        .catch((error) => error);
    },
    getRecords() {
      const params = {
        options: this.options,
        filters: this.filters,
        loaderText: 'Loading...',
      };
      let url = 'get-sync-records-by-company?format=json';
      if (this.isUserSyncLogsPage) {
        params.userId = this.$route.params.id;
        url = 'get-sync-records-by-user?format=json';
      } else if (this.isSyncDetailsPage) {
        params.guid = this.$route.params.guid;
        url = 'get-sync-records-by-guid?format=json';
      } else if (this.$route.params.companyId) {
        params.companyId = this.$route.params.companyId;
      }

      return this.$axios
        .post(url, params)
        .then((response) => {
          this.records = response.data.items;
          this.recordsCount = response.data.count;
        })
        .catch((error) => error);
    },
    goToUserDetail(userId) {
      this.$router.push({
        name: 'UserSyncLogs',
        params: { id: userId },
      });
    },
    goToSyncDetail(syncGuid) {
      this.$store.commit('updateSelectedSyncGuid', syncGuid);
      this.$router.push({
        name: 'SyncDetails',
        params: { guid: syncGuid },
      });
    },
  },
};
</script>

<style></style>
